import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 44px;
`;

const RightSide = styled(motion.div)`
	position: absolute;
	top: 17px;
	left: 286px;
`;

const LeftSide = styled(motion.div)`
	position: absolute;
	top: 15px;
	left: 34px;
`;

export const StatusBar = props => {
	return (
		<Container>
			<RightSide>
				<motion.svg width="78" height="13" viewBox="0 0 78 13" fill="none">
					<motion.path
						opacity="0.35"
						d="M54 0.527543H71C72.9178 0.527543 74.4725 2.08222 74.4725 4V9C74.4725 10.9178 72.9178 12.4725 71 12.4725H54C52.0822 12.4725 50.5275 10.9178 50.5275 9V4C50.5275 2.08221 52.0822 0.527543 54 0.527543Z"
						strokeWidth="1.05509"
						animate={{ stroke: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						opacity="0.4"
						d="M76 5V9.22034C76.8491 8.86291 77.4012 8.0314 77.4012 7.11017C77.4012 6.18894 76.8491 5.35744 76 5"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M52 4C52 2.89543 52.8954 2 54 2H71C72.1046 2 73 2.89543 73 4V9C73 10.1046 72.1046 11 71 11H54C52.8954 11 52 10.1046 52 9V4Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M34.5005 3.58753C36.967 3.58764 39.3393 4.55505 41.1269 6.28982C41.2615 6.42375 41.4766 6.42206 41.6092 6.28603L42.896 4.96045C42.9631 4.89146 43.0006 4.798 43 4.70076C42.9994 4.60353 42.9609 4.51052 42.893 4.44234C38.2011 -0.147446 30.7991 -0.147446 26.1072 4.44234C26.0392 4.51047 26.0006 4.60345 26 4.70069C25.9994 4.79792 26.0367 4.89141 26.1038 4.96045L27.391 6.28603C27.5235 6.42226 27.7388 6.42396 27.8733 6.28982C29.6612 4.55494 32.0337 3.58752 34.5005 3.58753ZM34.5359 7.67235C35.8911 7.67227 37.198 8.18644 38.2025 9.11497C38.3384 9.24674 38.5524 9.24389 38.6849 9.10853L39.9702 7.78295C40.0379 7.71342 40.0754 7.61909 40.0744 7.52108C40.0735 7.42306 40.034 7.32954 39.965 7.26142C36.9059 4.35683 32.1685 4.35683 29.1094 7.26142C29.0403 7.32953 29.0009 7.42311 29 7.52115C28.9991 7.6192 29.0368 7.71352 29.1046 7.78295L30.3895 9.10853C30.522 9.24389 30.736 9.24674 30.8719 9.11497C31.8758 8.18706 33.1816 7.67293 34.5359 7.67235ZM37.1496 10.3427C37.1515 10.441 37.1137 10.5357 37.0449 10.6046L34.8217 12.8948C34.7565 12.9621 34.6676 13 34.5749 13C34.4822 13 34.3933 12.9621 34.3282 12.8948L32.1045 10.6046C32.0358 10.5357 31.998 10.4409 32.0001 10.3426C32.0021 10.2443 32.0438 10.1512 32.1153 10.0853C33.5351 8.8595 35.6147 8.8595 37.0345 10.0853C37.106 10.1513 37.1476 10.2444 37.1496 10.3427Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M10 4C10 3.44772 10.4477 3 11 3H12C12.5523 3 13 3.44772 13 4V12C13 12.5523 12.5523 13 12 13H11C10.4477 13 10 12.5523 10 12V4Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M15 2C15 1.44772 15.4477 1 16 1H17C17.5523 1 18 1.44772 18 2V12C18 12.5523 17.5523 13 17 13H16C15.4477 13 15 12.5523 15 12V2Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M5 7.5C5 6.94772 5.44772 6.5 6 6.5H7C7.55228 6.5 8 6.94772 8 7.5V12C8 12.5523 7.55228 13 7 13H6C5.44772 13 5 12.5523 5 12V7.5Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M0 10C0 9.44772 0.447715 9 1 9H2C2.55228 9 3 9.44772 3 10V12C3 12.5523 2.55228 13 2 13H1C0.447715 13 0 12.5523 0 12V10Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
				</motion.svg>
			</RightSide>
			<LeftSide>
				<motion.svg width="54" height="21" viewBox="0 0 54 21" fill="none">
					<motion.path
						d="M16.5146 5.16748C14.2222 5.16748 12.5889 6.73486 12.5889 8.88086V8.89551C12.5889 10.9023 14.0098 12.3818 16.0386 12.3818C17.4888 12.3818 18.4116 11.6421 18.7998 10.8071H18.9463C18.9463 10.8877 18.939 10.9683 18.939 11.0488C18.8584 13.0703 18.1479 14.7109 16.4707 14.7109C15.5405 14.7109 14.8887 14.2275 14.6104 13.4878L14.5884 13.4146H12.728L12.7427 13.4951C13.0796 15.1138 14.5298 16.2637 16.4707 16.2637C19.1294 16.2637 20.7334 14.1543 20.7334 10.5947V10.5801C20.7334 6.77148 18.7705 5.16748 16.5146 5.16748ZM16.5073 10.9316C15.3062 10.9316 14.4346 10.0527 14.4346 8.82959V8.81494C14.4346 7.63574 15.3647 6.70557 16.5293 6.70557C17.7012 6.70557 18.6167 7.65039 18.6167 8.85889V8.87354C18.6167 10.0674 17.7012 10.9316 16.5073 10.9316Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M23.5586 9.15186C24.2324 9.15186 24.7231 8.63916 24.7231 7.99463C24.7231 7.34277 24.2324 6.8374 23.5586 6.8374C22.8921 6.8374 22.394 7.34277 22.394 7.99463C22.394 8.63916 22.8921 9.15186 23.5586 9.15186ZM23.5586 14.5864C24.2324 14.5864 24.7231 14.0811 24.7231 13.4292C24.7231 12.7773 24.2324 12.272 23.5586 12.272C22.8921 12.272 22.394 12.7773 22.394 13.4292C22.394 14.0811 22.8921 14.5864 23.5586 14.5864Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M31.5107 16H33.3198V13.9712H34.7407V12.4111H33.3198V5.43115H30.6465C29.2109 7.61377 27.7095 10.0454 26.3398 12.4258V13.9712H31.5107V16ZM28.0977 12.4551V12.3452C29.123 10.5508 30.3462 8.59521 31.4302 6.93994H31.54V12.4551H28.0977Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
					<motion.path
						d="M38.6206 16H40.5103V5.43115H38.6279L35.8667 7.37207V9.15186L38.4961 7.2915H38.6206V16Z"
						animate={{ fill: props.theme.name === "dark" ? "rgba(255,255,255,1.0)" : "rgba(0,0,0,1.0)" }}
						initial={false}
					/>
				</motion.svg>
			</LeftSide>
		</Container>
	);
};
